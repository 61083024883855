<template>
	<router-link
		:to="article.url"
	>
		<div
			:class="[ cardClass,
				'relative mb-2 overflow-hidden md:mx-0',
				'after:absolute after:w-full after:h-1/2 after:left-0 after:bottom-0 after:bg-gradient-to-t after:from-[#000] after:opacity-70'
			]"
		>
			<WwImage
				:src="article.image"
				:alt="article.title"
				:width="imageWidth"
				:height="imageHeight"
				:image-path="BLOG_IMAGES"
				:fuzzy-load="true"
				class="w-full h-full"
			/>
			<div class="absolute z-10 flex bottom-3 left-3">
				<div
					v-if="!hasInvalidCategory"
					class="px-3 py-1 text-sm font-semibold tracking-wide text-white bg-green-500 rounded-full"
				>
					{{ article.category }}
				</div>
			</div>
		</div>
		<h2
			:class="[ textClass, 'text-lg font-bold']"
			v-html="article.title"
		/>
	</router-link>
</template>

<script async>
import WwImage from '@/components/multiUse/WwImage.vue'
import { BLOG_IMAGES } from '@/constants/images/image-folder-paths.js'
import { formatUnixDate } from '@/utils/formatUnixTime.js'

export default {
	components: {
		WwImage
	},
	props: {
		article: {
			type: Object,
			required: true
		},
		imageHeight: {
			type: Number,
			default: 360
		},
		imageWidth: {
			type: Number,
			default: 500
		},
		textClass: {
			type: String,
			default: ''
		},
		cardClass: {
			type: String,
			default: 'rounded-xl'
		}
	},
	data() {
		return {
			BLOG_IMAGES
		}
	},
	computed: {
		hasInvalidCategory() {
			return !!Number(this.article?.category)
		}
	},
	methods: {
		formatUnixDate
	}
}
</script>
